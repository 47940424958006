import { render } from './funcs'
import { Footer } from './footer'
import './index.css'

const displayContent = () => {
  document.body.style.visibility = 'visible'
  document.body.style.opacity = '1'
}

const main = () => {
  const parentDiv: HTMLElement = document.querySelector('#left')
  const canvas: HTMLCanvasElement = document.querySelector('#canvas')
  const ctx = canvas.getContext('2d')
  Footer(document.querySelector('footer'), 2010)
  const observer = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      const size = Math.min(parentDiv.offsetWidth, parentDiv.offsetHeight)
      const canvasSize = size * devicePixelRatio
      canvas.width = canvasSize
      canvas.height = canvasSize
      ctx.scale(devicePixelRatio, devicePixelRatio)
      render({ size, ctx })
    })
  })
  observer.observe(canvas)
}

window.addEventListener('DOMContentLoaded', () => {
  main()
  displayContent()
})

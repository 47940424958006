import { NoiseFunction2D, createNoise2D } from 'simplex-noise'

// constants
export const baseColor = '#121212'

interface Node {
  x: number
  y: number
}

interface NodeProps {
  ctx: CanvasRenderingContext2D
  t: number
  n: number
  size: number
  r: number
  d_theta: number
  noise: NoiseFunction2D
  nodes: Array<Node>
}

interface EdgeProps {
  ctx: CanvasRenderingContext2D
  n: number
  size: number
  max_dist: number
  nodes: Array<Node>
}

interface RenderProps {
  size: number
  ctx: CanvasRenderingContext2D
}

export { Node, NodeProps, EdgeProps, RenderProps }

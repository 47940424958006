import { createNoise2D, NoiseFunction2D } from 'simplex-noise'
import { Node, NodeProps, EdgeProps, RenderProps, baseColor } from './protocols'

const angle_delta = (a: number, b: number) => {
  const d = 2
  a = a % (d * Math.PI)
  b = b % (d * Math.PI)

  let c, cc
  if (b > a) {
    c = b - a
    cc = d * Math.PI - c
  } else {
    cc = a - b
    c = d * Math.PI - cc
  }
  if (cc < c) {
    return -cc / Math.PI
  }
  return c / Math.PI
}

const jitter = (size: number, i: number, t: number, noise: NoiseFunction2D) => {
  const a = size * 0.1
  const b = size * 0.8
  const mag = a * 1.33
  const coeff = i * a + t / b
  return noise(i * a + t / b, 0) * mag
}

const updateNodes = ({
  ctx,
  t,
  n,
  size,
  r,
  d_theta,
  noise,
  nodes,
}: NodeProps) => {
  const radius = size * 0.006
  const leftMargin = size * 0.025
  const coeff = size * 0.02
  for (let i = 0; i < n; i++) {
    const angle = i * d_theta
    const diff = angle_delta(0, angle)
    const offset_scale = Math.pow(Math.E, -(diff * diff) * coeff)
    const x_offset = jitter(size, i, t, noise)
    const y_offset = jitter(size, i, t, noise)
    const x =
      size * 0.5 - leftMargin + Math.cos(angle) * r + x_offset * offset_scale
    const y = size * 0.5 + Math.sin(angle) * r + y_offset * offset_scale
    nodes[i].x = x
    nodes[i].y = y
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, Math.PI * 2)
    ctx.fill()
  }
  return nodes
}

const updateEdges = ({ ctx, n, size, max_dist, nodes }: EdgeProps) => {
  //draw links
  for (let i = 0; i < n; i++) {
    for (let j = i + 1; j < n; j++) {
      const pa = nodes[i]
      const pb = nodes[j]
      const dist = Math.sqrt(
        Math.pow(pb.x - pa.x, 2) + Math.pow(pb.y - pa.y, 2)
      )
      const ndist = (dist / size) * 0.5
      // const lineWidth = (max_dist - dist) / (max_dist / 2)
      const lineWidth = (max_dist - dist) * ndist
      ctx.lineWidth = lineWidth
      if (lineWidth > 0) {
        ctx.beginPath()
        ctx.moveTo(pa.x, pa.y)
        ctx.lineTo(pb.x, pb.y)
        ctx.stroke()
      }
    }
  }
}

export const render = ({ size, ctx }: RenderProps) => {
  const n = 300
  const max_dist = size * 0.08
  const r = size * 0.4
  const d_theta = (2 * Math.PI) / n
  let nodes: Array<Node> = new Array(n).fill(null).map(_ => ({ x: 0, y: 0 }))
  const noise = createNoise2D()
  let t = 0
  let step = size * 0.002

  // prettier-ignore
  const nodeConsts = { ctx, n, size, r, d_theta, noise, }
  const edgeConsts = { ctx, n, size, max_dist, nodes }

  ctx.fillStyle = baseColor
  ctx.strokeStyle = baseColor
  const draw = () => {
    ctx.clearRect(0, 0, size, size)
    t += step
    nodes = updateNodes({ t: t, nodes: nodes, ...nodeConsts })
    updateEdges({ nodes, ...edgeConsts })

    window.requestAnimationFrame(draw)
  }
  draw()
}

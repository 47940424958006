export const Footer = (div: HTMLElement, startYear: number) => {
  const year = new Date().getFullYear()
  //prettier-ignore
  const yearStr = year - startYear === 0 
    ? ` © ${startYear}`
    : ` © ${startYear}–${year}`
  div.innerHTML = `
                      Seth Brown
                      ${yearStr}
                    `
}
